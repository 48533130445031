<template>
    <defaultTemplate  class="text-center text-white bg-primary" page="signup">
        <div class="text-center pd-5 position-relative main-slot">
          <div class="text-center">
            <img src="@/assets/warning.png" alt="" >
            <h1 >หน้าเว็บไซต์นี้ “อยู่ระหว่างปรับปรุงการให้บริการ”</h1>
            <h2>กรุณาติดต่อ EXIM CONTACT CENTER โทร 0 2169 9999</h2>
            <a :href="authServer" class="underline text-white">กลับไป หน้าเข้าสู่่ระบบ EXIM1</a>
          </div>
           
        </div>
    </defaultTemplate>
</template>
<script>
import defaultTemplate from "@/template/DefaultTemplate";
export default {
    components: {
        defaultTemplate
    },
    data ()
    {
        return {
            authServer: process.env.VUE_APP_OIDC_SERVE,
        }
    }
}
</script>
<style lang="scss" scoped>
img{
    margin-top: 40px;
}
h1{
    font-weight: 500;
    font-size: 48px;
    margin:30px 0;
}
h2{
    font-weight: 500;
    font-size: 32px;
    margin: 30px 0;
}

a{
    font-weight: 500;
    font-size: 20px;
    margin: 35px 0;
}
@media (min-width: 1920px) {
    .footer {
        width: 100%;
        position: absolute;
        bottom: 25px;
        margin: 0 auto;
    }

    .main-slot {
        min-height: calc(100vh - 235px);
    }
}

.main-slot {
    min-height: calc(100vh - 402px);
}

@media (min-width: 1366px) and (max-width: 1367px) {
    .padding-grid {
        min-height: calc(100vh - 437px) !important;
    }

    .main-slot {
        min-height: calc(100vh - 146px) !important;
    }
}

@media (min-width: 1281px) {
    .padding-grid {
        min-height: 54vh !important;
    }

    .main-slot {
        min-height: calc(100vh - 146px) !important;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .padding-grid {
        min-height: calc(100vh - 444px) !important;
    }

    .main-slot {
        min-height: calc(100vh - 146px) !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .bg-user-second {
        height: 63vh;
    }

    .main-slot {
        min-height: calc(100vh - 200px) !important;
    }
}

@media (min-width: 767px) and (max-width: 834px) {
    .padding-grid {
        min-height: calc(100vh - 444px) !important;
    }

    .main-slot {
        min-height: calc(100vh - 146px) !important;
    }
}

@media (max-width: 767px) {
    .main-slot {
        min-height: calc(100vh - 355px) !important;
    }
}

@media (max-width: 414px) {
    .main-slot {
        min-height: calc(100vh - 355px) !important;
    }
}

@media (max-width: 375px) {
    .main-slot {
        min-height: calc(100vh - 349px) !important;
    }
}
</style>